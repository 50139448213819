import {defineStore} from 'pinia';
import {getFundmentals, getHistoricalQuotes, getSnapQuotes} from '../services/FAPI/equities';
import {FundamentalsOptions, HistoricalQuotesOptions, SnapQuotesOptions} from '../data-types';

export const useFAPIStore = defineStore('FAPI', {
  state: () => ({
    loading: false,
    fundamentals: {},
    historicalQuotes: {} as Record<string, string | number>,
    snapQuoteOpenTimestamp: '',
  }),
  actions: {
    async fetchFundamentals(params: FundamentalsOptions) {
      try {
        this.loading = true;
        this.fundamentals = {};
        const response = await getFundmentals(params);
        this.fundamentals = response?.data ?? {};
        this.loading = false;
      } catch {
        this.loading = false;
        this.fundamentals = {};
      }
    },
    async fetchHistoricalQuotes(params: HistoricalQuotesOptions) {
      try {
        this.loading = true;
        this.historicalQuotes = {};
        const response = await getHistoricalQuotes(params);
        const data = response?.data;
        if (data) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          this.historicalQuotes = data.map((value: Record<string, string | undefined>) => [
            value?.lastTimestamp ? Date.parse(value?.lastTimestamp) : '',
            value.last,
          ]);
        }
        this.loading = false;
      } catch {
        this.loading = false;
        this.historicalQuotes = {};
      }
    },
    async fetchSnapQuotes(params: SnapQuotesOptions) {
      try {
        this.loading = true;
        this.snapQuoteOpenTimestamp = '';
        const response = await getSnapQuotes(params);
        if (response?.data) {
          this.snapQuoteOpenTimestamp = response?.data[0].openTimestamp;
        }
        this.loading = false;
      } catch {
        this.loading = false;
        this.snapQuoteOpenTimestamp = '';
      }
    },
  },
});
