import useChartOptions from '../composables/useHighChartOptions';
import useChartTimeFrames from '../composables/useChartTimeFrames';
import type {IChartData} from './useHighChartOptions';
import {useAuthStore, useHLAPIStore, useFAPIStore} from '../store/stores';
import {storeToRefs} from 'pinia';
import {getFormattedNumber} from '../util/numberHelper';
import {formatDate, formatDateTimeWithTimeZone} from '../util/dateHelper';
import {CommonObject, PlottablePoint} from '../data-types';
import {getChartTimeline} from '../util/chartHelper';
import {computed, ComputedRef, Ref, ref} from 'vue';

export default async function usePriceInIt() {
  const defaultTimeFrame = ref<string>('all');
  const chartTimeFrame = useChartTimeFrames();
  const chartOptions = ref({});
  const chartReference: Ref = ref({});

  const store = useAuthStore();

  await store.validateJWT();
  const {idNotation, isError} = storeToRefs(store);
  const hlAPIStore = useHLAPIStore();
  await hlAPIStore.getByInstrumentNotation(idNotation.value);
  const securityType = computed(() => Number(hlAPIStore.securityType));
  const {
    notationInfo,
    pricesInfo,
    bidAskInfo,
    instrumentData,
    keyFigures1W,
    keyFigures1M,
    keyFigures3M,
    keyFigures1Y,
    debtInstrumentKeyData,
    calendarEventList,
  } = storeToRefs(hlAPIStore);

  const {snapQuoteOpenTimestamp, fundamentals} = storeToRefs(useFAPIStore());

  await getChartData(idNotation.value, defaultTimeFrame.value);

  async function onTimeFrameChange(event: Event) {
    defaultTimeFrame.value = (event.target as HTMLInputElement).value;
    await getChartData(idNotation.value, defaultTimeFrame.value);
  }

  async function getChartData(idNo: string, timeFrame: string) {
    const fapiStore = useFAPIStore();
    showLoaderOnChart();
    await fapiStore.fetchHistoricalQuotes(getHistoryQuoteOptions(`XX:${idNotation.value}`, timeFrame));
    hideLoaderOnChart();
    let plotLines: CommonObject[] = [{}];
    let max: string | number | null = null;
    const xAxisData: {min: number | undefined} = {
      min: undefined,
    };

    if (timeFrame === 'oneDay') {
      const previousClose: ComputedRef<string> = computed(() =>
        getFormattedNumber(pricesInfo.value?.previousClose?.price, 2)
      );
      max = Number(previousClose.value);
      plotLines = [
        {
          zIndex: 20,
          color: '#011951',
          width: 2,
          value: max,
        },
      ];
      xAxisData.min = Date.parse(snapQuoteOpenTimestamp.value);
    }

    const chartData = {
      series: [
        {
          data: fapiStore.historicalQuotes,
        },
      ],
    };
    const options = useChartOptions(chartData as IChartData);
    chartOptions.value = {
      ...options,
      yAxis: {
        ...options.yAxis,
        plotLines,
      },
      xAxis: {
        ...options.xAxis,
        ...xAxisData,
      },
      tooltip: {
        ...options.tooltip,
        formatter(this: PlottablePoint): string {
          const formattedPrice = `USD $${getFormattedNumber(this.y, 2)}`;
          const formattedDate = formatDate(this.key, 'MMM D, YYYY');
          const formattedTime = formatDateTimeWithTimeZone(this.key, 'MMM D, YYYY  h:mm A [ET]');
          const formattedX = timeFrame === 'oneDay' ? `${formattedTime}` : formattedDate;

          return `<div><span>${String(formattedPrice)}</span><br/><span>${String(formattedX)}</span></div>`;
        },
      },
    };
  }

  function showLoaderOnChart() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    chartReference?.value?.chart?.showLoading();
  }

  function hideLoaderOnChart() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    chartReference?.value?.chart?.hideLoading();
  }
  function getHistoryQuoteOptions(identifier: string, timeframe: string) {
    return {
      identifier,
      adjustForSplits: true,
      ...getChartTimeline(timeframe),
    };
  }

  return {
    isError,
    chartTimeFrame,
    defaultTimeFrame,
    onTimeFrameChange,
    chartOptions,
    securityType,
    notationInfo,
    pricesInfo,
    bidAskInfo,
    instrumentData,
    keyFigures1W,
    keyFigures1M,
    keyFigures3M,
    keyFigures1Y,
    chartReference,
    debtInstrumentKeyData,
    fundamentals,
    calendarEventList,
  };
}
