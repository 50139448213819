import {nullValue} from '../shared/constants';
export class CssHelper {
  public static getClassForChange(value: number | string | undefined) {
    if (value === undefined) {
      return nullValue;
    }
    const updatedValue = Number.parseFloat(`${value}`).toFixed(2);
    if (Number.parseFloat(updatedValue) > 0) {
      return 'fds-changeup';
    } else if (Number.parseFloat(updatedValue) < 0) {
      return 'fds-changedown';
    }
    return 'fds-changenone';
  }
}
