import {sub} from 'date-fns';

export function getChartTimeline(selectedTimeline: string) {
  const currentTime = new Date().toISOString();

  switch (selectedTimeline) {
    case 'oneDay':
      return {
        startOffset: -1,
        endOffset: 0,
        offsetUnit: 'TRADING_DAY',
        resolution: 'MINUTE',
      };
    case 'oneWeek':
      return {
        startOffset: -4,
        endOffset: 0,
        offsetUnit: 'TRADING_DAY',
        resolution: 'MINUTE_15',
      };
    case 'oneMonth':
      return {
        startTimestamp: sub(new Date(), {months: 1}).toISOString(),
        endTimestamp: currentTime,
        resolution: 'DAY',
      };
    case 'threeMonth':
      return {
        startTimestamp: sub(new Date(), {months: 3}).toISOString(),
        endTimestamp: currentTime,
        resolution: 'DAY',
      };
    case 'sixMonth':
      return {
        startTimestamp: sub(new Date(), {months: 6}).toISOString(),
        endTimestamp: currentTime,
        resolution: 'DAY',
      };
    case 'oneYear':
      return {
        startTimestamp: sub(new Date(), {years: 1}).toISOString(),
        endTimestamp: currentTime,
        resolution: 'DAY',
      };
    case 'threeYear':
      return {
        startTimestamp: sub(new Date(), {years: 3}).toISOString(),
        endTimestamp: currentTime,
        resolution: 'MONTH',
      };
    case 'fiveYear':
      return {
        startTimestamp: sub(new Date(), {years: 5}).toISOString(),
        endTimestamp: currentTime,
        resolution: 'MONTH',
      };
    case 'all':
    default:
      return {
        startTimestamp: sub(new Date(), {years: 10}).toISOString(),
        endTimestamp: currentTime,
        resolution: 'MONTH',
      };
  }
}
