<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'PageNotFound',
});
</script>

<template>
  <div>
    <h1>Page Not Found</h1>
    <span>The link you followed might be broken, or you might have mistyped the URL.</span>
  </div>
</template>
