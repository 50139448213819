<template>
  <suspense>
    <div :class="$style.container">
      <router-view></router-view>
    </div>
  </suspense>
</template>

<style module>
.container {
  padding: 20px;
}
</style>
