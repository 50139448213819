import {nullValue, suffixes} from '../shared/constants';
import {TypeAlphaNumeric} from '../data-types';

export function getFormattedNumber(value: TypeAlphaNumeric, decimal: number) {
  if (value === undefined) {
    return nullValue;
  }
  if (value == null) {
    return nullValue;
  }
  return Number.parseFloat(value as unknown as string).toFixed(decimal);
}

export function abbreviatedNumber(value: TypeAlphaNumeric, decimal = 0) {
  if (value === undefined) {
    return nullValue;
  }
  if (value == null) {
    return nullValue;
  }
  const number = Number.parseFloat(value as unknown as string);
  const magnitude = (Math.log10(number) / 3) | 0;
  const prefix = suffixes[magnitude];
  const scale = Math.pow(10, magnitude * 3);
  const scaled = number / scale;
  return scaled.toFixed(decimal) + prefix;
}
export function numberWithCommas(value: number | undefined) {
  if (value === undefined) {
    return nullValue;
  }

  return value.toLocaleString('en-US');
}
