import {createRouter, createWebHistory} from 'vue-router';
import PriceWidget from './views/PriceWidget.vue';
import LoginJWT from './views/LoginJWT.vue';
import PageNotFound from './views/pageNotFound.vue';

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/price-widget',
      name: 'price-widget',
      component: PriceWidget,
      meta: {
        pageType: 'priceWidget',
      },
    },
    {
      path: '/price-page',
      name: 'price-page',
      component: PriceWidget,
      meta: {
        pageType: 'pricePage',
      },
    },
    {
      path: '/loginJWT',
      name: 'loginJWT',
      component: LoginJWT,
    },
    {
      path: '/:pathMatch(.*)*',
      component: PageNotFound,
    },
  ],
});
