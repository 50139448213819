import axios, {AxiosRequestConfig} from 'axios';
import {FundamentalsOptions, HistoricalQuotesOptions, SnapQuotesOptions} from '../../data-types';

export const getFundmentals = function (fundamentalsOptions: FundamentalsOptions) {
  const options: AxiosRequestConfig = {
    url: 'api/financial-api/equitiesFundamentals',
    method: 'get',
    params: fundamentalsOptions,
    headers: {
      Accept: 'application/json',
    },
  };

  return axios(options);
};

export const getHistoricalQuotes = function (historicalQuotesOptions: HistoricalQuotesOptions) {
  const options = {
    url: 'api/financial-api/historicalQuotes',
    method: 'get',
    params: historicalQuotesOptions,
    headers: {
      Accept: 'application/json',
    },
  };
  return axios(options);
};

export const getSnapQuotes = function (historicalQuotesOptions: SnapQuotesOptions) {
  const options = {
    url: 'api/financial-api/quotesSnapQuotes',
    method: 'get',
    params: historicalQuotesOptions,
    headers: {
      Accept: 'application/json',
    },
  };
  return axios(options);
};
