<script lang="ts">
import {defineComponent} from 'vue';
import stockSecurityType from './stockSecurityType.vue';
import optionsSecurityType from './optionsSecurityType.vue';
import bondsSecurityType from './bondsSecurityType.vue';
import DebtSecurityType from './debtSecurityType.vue';

export default defineComponent({
  name: 'SymbolTypes',
  components: {
    stockSecurityType,
    optionsSecurityType,
    bondsSecurityType,
    DebtSecurityType,
  },
  props: {
    securityType: {
      type: Number,
      required: true,
    },
    priceInfo: {
      type: Object,
      required: true,
    },
    bidAskInfo: {
      type: Object,
      required: true,
    },
    instrumentData: {
      type: Object,
      required: true,
    },
    keyFiguresOneWeekData: {
      type: Object,
      required: true,
    },
    keyFiguresOneMonthData: {
      type: Object,
      required: true,
    },
    keyFiguresThreeMonthData: {
      type: Object,
      required: true,
    },
    keyFiguresOneYearData: {
      type: Object,
      required: true,
    },
    fundamentals: {
      type: Object,
      required: true,
    },
    calendarEvent: {
      type: Object,
    },
    debtInstrumentKeyData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      props,
    };
  },
});
</script>

<template>
  <div>
    <stock-security-type
      v-if="props.securityType === 2"
      :price-info="props.priceInfo"
      :bid-ask-info="props.bidAskInfo"
      :instrument-data="props.instrumentData"
      :key-figures-one-month-data="props.keyFiguresOneMonthData"
      :key-figures-one-week-data="props.keyFiguresOneWeekData"
      :key-figures-three-month-data="props.keyFiguresThreeMonthData"
      :key-figures-one-year-data="props.keyFiguresOneYearData"
      :fundamentals="props.fundamentals"
      :calendar-event="props.calendarEvent"
    ></stock-security-type>
    <DebtSecurityType
      v-if="props.securityType === 3"
      :instrument-data="instrumentData"
      :price-info="props.priceInfo"
      :debt-instrument-key-data="props.debtInstrumentKeyData"
    ></DebtSecurityType>
    <bonds-security-type v-if="props.securityType === 4"></bonds-security-type>
    <options-security-type v-if="props.securityType === 5"></options-security-type>
  </div>
</template>
