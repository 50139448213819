export default function useChartTimeFrames() {
  return [
    {
      value: 'oneDay',
      text: '1D',
    },
    {
      value: 'oneWeek',
      text: '1W',
    },
    {
      value: 'oneMonth',
      text: '1M',
    },
    {
      value: 'threeMonth',
      text: '3M',
    },
    {
      value: 'sixMonth',
      text: '6M',
    },
    {
      value: 'oneYear',
      text: '1Y',
    },
    {
      value: 'threeYear',
      text: '3Y',
    },
    {
      value: 'fiveYear',
      text: '5Y',
    },
    {
      value: 'all',
      text: 'All',
    },
  ];
}
