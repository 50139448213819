import Highcharts from 'highcharts';
import accessibility from 'highcharts/modules/accessibility';
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
accessibility(Highcharts);
noDataToDisplay(Highcharts);

export interface IChartData {
  chartTitle?: string;
  xAxisTitle?: string;
  yAxisTitle?: string;
  colors?: [];
  series: [];
}

export default function useChartOptions(chartData: IChartData) {
  const options: Highcharts.Options = {
    chart: {
      type: 'area',
      backgroundColor: 'transparent',
    },
    accessibility: {
      enabled: true,
    },
    lang: {
      noData: 'No data available',
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    xAxis: {
      visible: false,
      ordinal: true,
    },
    yAxis: {
      opposite: true,
      gridLineDashStyle: 'Dash',
      title: {
        text: '',
      },
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 2,
    },
    plotOptions: {
      series: {
        marker: {
          fillColor: '#011951',
        },
        turboThreshold: 10000,
      },
      area: {
        lineColor: '#011951',
        threshold: null,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, '#F4F4F4'],
            [1, '#FFFFFF'],
          ],
        },
      },
    },
    series: chartData.series,
  };
  return options;
}
