<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'SafraFooter',
});
</script>

<template>
  <div class="fds-footer">
    <span class="fds-logo"><span class="sr-only">FactSet Logo</span></span>
    <p>
      Quotes delayed at least 15 minutes. Market data provided by
      <a href="https://www.factset.com/" target="blank">FactSet.</a> Powered and implemented by
      <a href="https://www.factset.com/solutions/digital-solutions" target="blank">FactSet Digital Solutions. </a>
      <a href="https://www.factset.com/legal" target="blank">Legal Statement.</a>
    </p>
  </div>
</template>
