<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'BondsSecurityType',
});
</script>

<template>
  <div class="fds-gray-area">
    <dl>
      <dt>Previous close</dt>
      <dd>320.05</dd>

      <dt>Open</dt>
      <dd>318.23</dd>

      <dt>Bid</dt>
      <dd>321.31</dd>

      <dt>Ask</dt>
      <dd>321.35</dd>

      <dt>Bid size</dt>
      <dd>15.0M</dd>

      <dt>Ask size</dt>
      <dd>13.2M</dd>

      <dt>Market cap</dt>
      <dd>748B</dd>

      <dt>Volume</dt>
      <dd>56M</dd>

      <dt>Average vol (3M)</dt>
      <dd>14M</dd>

      <dt>P/E ratio</dt>
      <dd>22.3</dd>

      <dt>EPS</dt>
      <dd>3.51</dd>

      <dt>Beta</dt>
      <dd>1.05</dd>

      <dt>Dividend</dt>
      <dd>3.2 (1.0%)</dd>

      <dt>Ex-dividend date</dt>
      <dd>22.01.2021</dd>

      <dt>Earnings date</dt>
      <dd>14.02.2021</dd>

      <dt>1W Performance</dt>
      <dd><span class="fds-changeup">+ 3.2%</span></dd>

      <dt>1M Performance</dt>
      <dd><span class="fds-changeup">+ 10.2%</span></dd>

      <dt>1Y Performance</dt>
      <dd><span class="fds-changedown">&minus; 1.2%</span></dd>
    </dl>

    <div class="fds-day-range-wrapper">
      <span>Day Range</span>
      <div aria-label="Low 318.23, Current 326.05, High 333.02" class="fds-day-range-slider">
        <div class="fds-range-upper">
          <span aria-label="Low">318.23</span>
          <span aria-label="High">333.02</span>
        </div>
        <div class="fds-range-lower">
          <span class="fds-range-dot" style="margin-left: 20%" aria-label="Current"
            ><span class="sr-only">326.05</span></span
          >
        </div>
      </div>
    </div>
  </div>
</template>
