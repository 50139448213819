<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'OptionsSecurityType',
});
</script>

<template>
  <div class="fds-gray-area">
    <dl>
      <dt>Contract size</dt>
      <dd>100</dd>
      <dt>Bid size</dt>
      <dd>112</dd>
      <dt>Ask size</dt>
      <dd>450</dd>
      <dt>Last</dt>
      <dd>0.44 &#x2022; 18:15:28</dd>
      <dt>Previous close</dt>
      <dd>0.40</dd>
      <dt>Net change</dt>
      <dd>+0.04</dd>
      <dt>1D change</dt>
      <dd><span class="fds-changeup">+ 3.2%</span></dd>
      <dt>Option type</dt>
      <dd>Put</dd>
      <dt>Exercise style</dt>
      <dd>American</dd>
      <dt>Expiry</dt>
      <dd>17.06.2022</dd>
      <dt>Delivery date</dt>
      <dd>19.06.2022</dd>
      <dt>Delivery type</dt>
      <dd>Physical</dd>
      <dt>Strike</dt>
      <dd>120.00</dd>
      <dt>Underlying price</dt>
      <dd>115.20</dd>
      <dt>In(+) / Out(-)</dt>
      <dd>+4.17%</dd>
      <dt>Volume</dt>
      <dd>14,564</dd>
      <dt>Open interest</dt>
      <dd>563</dd>
      <dt>Tick size</dt>
      <dd>.01</dd>
    </dl>
  </div>
</template>
