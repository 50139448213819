<template>
  <div>
    <h4>Generate JWT</h4>
    <div>
      <table>
        <tr>
          <th></th>
          <th></th>
        </tr>
        <tr>
          <td>ID Notation</td>
          <td>
            <input id="idNot" v-model="idNotation" type="text" />
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <button @click="generateToken">Generate Token</button>
          </td>
        </tr>
      </table>
      <div v-if="generatedToken">
        <h4>Generated token is:</h4>
        {{ generatedToken }}
      </div>
    </div>
    <hr />
    <table>
      <tr>
        <td>Enter token to decode</td>
        <td>
          <input v-model="tokenToDecode" type="text" style="width: 1200px" />
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <button id="decodeBtn" @click="decodeToken">Decode JWT</button>
        </td>
      </tr>
      <tr>
        <td>Decrypted Data</td>
        <td>
          {{ decryptedData }}
        </td>
      </tr>
    </table>
    <hr />

    <div v-if="generatedToken">
      <div>
        <h4>Pages:</h4>
        <form id="priceWidget" target="_self" method="post" action="price-widget">
          Price Widget
          <input type="hidden" name="ssoToken" style="width: 1200px" :value="generatedToken" />
          <input type="submit" value="Open Page" />
        </form>
        <form id="pricePage" target="_self" method="post" action="price-page">
          Price Page
          <input type="hidden" name="ssoToken" style="width: 1200px" :value="generatedToken" />
          <input type="submit" value="Open Page" />
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, computed} from 'vue';
import {useRouter} from 'vue-router';
import axios from 'axios';

export default defineComponent({
  name: 'LoginJWT',
  setup() {
    const tokenToDecode = ref<string>('');
    const idNotation = ref('67728090');
    const generatedToken = ref<string>('');
    const decryptedData = ref();
    const router = useRouter();
    const routeNames = computed(() => router.options.routes.map((route) => route.name as string));

    async function generateToken() {
      const body = {
        ID_NOTATION: idNotation.value,
      };
      const {data} = await axios.post('api/generateJWT', body, {});
      if (!data) {
        return;
      }
      generatedToken.value = data.token;
    }

    async function decodeToken() {
      const body = {
        authorization: tokenToDecode.value,
      };
      const {data} = await axios.post('api/decodeJWT', body, {});
      if (!data) {
        return;
      }
      decryptedData.value = data;
    }

    return {
      generateToken,
      generatedToken,
      tokenToDecode,
      decodeToken,
      decryptedData,
      idNotation,
      routeNames,
    };
  },
});
</script>
