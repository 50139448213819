import axios from 'axios';
import {Options, NotationOptions} from '../../data-types';
import {noData} from '../../shared/constants';

export async function getByInstrumentNotation(options: Options) {
  const config = {
    params: options,
  };
  const response = await axios.get('/api/hlapi/getByInstrumentNotation', config);
  if (!response?.data) {
    throw new Error(noData);
  }
  return response.data;
}
export async function getByNotation(options: NotationOptions) {
  const config = {
    params: options,
  };
  const response = await axios.get('/api/hlapi/getByNotation', config);
  if (!response?.data) {
    throw new Error(noData);
  }
  return response.data;
}
