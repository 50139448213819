<script lang="ts">
import {defineComponent, ref, onMounted} from 'vue';
import SafraFooter from './SafraFooter.vue';
import PriceWidgetHeader from '../components/priceWidgetHeader.vue';
import usePriceInIt from '../composables/priceInIt';
import {useRoute} from 'vue-router';
import symbolTypes from '../components/symbolTypes.vue';

export default defineComponent({
  name: 'PriceWidget',
  components: {
    symbolTypes,
    SafraFooter,
    PriceWidgetHeader,
  },
  async setup() {
    const route = useRoute();
    const pageType = ref<string>('');
    onMounted(() => {
      pageType.value = (route.meta.pageType as string) ?? '';
    });
    const {
      isError,
      chartTimeFrame,
      defaultTimeFrame,
      onTimeFrameChange,
      chartOptions,
      securityType,
      notationInfo,
      pricesInfo,
      bidAskInfo,
      instrumentData,
      keyFigures1W,
      keyFigures1M,
      keyFigures3M,
      keyFigures1Y,
      chartReference,
      debtInstrumentKeyData,
      fundamentals,
      calendarEventList,
    } = await usePriceInIt();
    return {
      isError,
      chartTimeFrame,
      defaultTimeFrame,
      onTimeFrameChange,
      chartOptions,
      securityType,
      notationInfo,
      pricesInfo,
      bidAskInfo,
      instrumentData,
      keyFigures1W,
      keyFigures1M,
      keyFigures3M,
      keyFigures1Y,
      chartReference,
      debtInstrumentKeyData,
      fundamentals,
      calendarEventList,
      pageType,
    };
  },
});
</script>

<template>
  <div v-if="isError">Invalid JWT token, please pass a valid one.</div>
  <div v-else-if="pageType === 'priceWidget'">
    <PriceWidgetHeader
      :instrument-data="instrumentData"
      :price-info="pricesInfo"
      :notation-info="notationInfo"
    ></PriceWidgetHeader>
    <div class="fds-chart-container">
      <highcharts ref="chartReference" :options="chartOptions"></highcharts>
    </div>

    <div role="radiogroup" aria-labelledby="chartControlsLabel" class="fds-radio-toolbar">
      <p id="chartControlsLabel" class="sr-only">Chart Controls</p>
      <div v-for="(timeFrame, i) in chartTimeFrame" :key="i">
        <input
          :id="timeFrame.value"
          type="radio"
          name="radioTimeframe"
          :value="timeFrame.value"
          :checked="timeFrame.value == defaultTimeFrame"
          @change="onTimeFrameChange($event)"
        />
        <label :for="timeFrame.value">{{ timeFrame.text }}</label>
      </div>
    </div>
    <symbol-types
      :security-type="securityType"
      :price-info="pricesInfo"
      :bid-ask-info="bidAskInfo"
      :instrument-data="instrumentData"
      :key-figures-one-month-data="keyFigures1M"
      :key-figures-one-week-data="keyFigures1W"
      :key-figures-three-month-data="keyFigures3M"
      :key-figures-one-year-data="keyFigures1Y"
      :fundamentals="fundamentals"
      :calendar-event="calendarEventList"
      :debt-instrument-key-data="debtInstrumentKeyData"
    >
    </symbol-types>
    <SafraFooter></SafraFooter>
  </div>
  <div v-else-if="pageType === 'pricePage'" class="fds-side-by-side">
    <PriceWidgetHeader
      :instrument-data="instrumentData"
      :price-info="pricesInfo"
      :notation-info="notationInfo"
    ></PriceWidgetHeader>
    <div class="fds-page-content">
      <div class="fds-content-left">
        <div class="fds-chart-container">
          <highcharts ref="chartReference" :options="chartOptions"></highcharts>
        </div>

        <div role="radiogroup" aria-labelledby="chartControlsLabel1" class="fds-radio-toolbar">
          <p id="chartControlsLabel1" class="sr-only">Chart Controls</p>
          <div v-for="(timeFrame, i) in chartTimeFrame" :key="i">
            <input
              :id="timeFrame.value"
              type="radio"
              name="radioTimeframe"
              :value="timeFrame.value"
              :checked="timeFrame.value == defaultTimeFrame"
              @change="onTimeFrameChange($event)"
            />
            <label :for="timeFrame.value">{{ timeFrame.text }}</label>
          </div>
        </div>
      </div>
      <div class="fds-content-right">
        <symbol-types
          :security-type="securityType"
          :price-info="pricesInfo"
          :bid-ask-info="bidAskInfo"
          :instrument-data="instrumentData"
          :key-figures-one-month-data="keyFigures1M"
          :key-figures-one-week-data="keyFigures1W"
          :key-figures-three-month-data="keyFigures3M"
          :key-figures-one-year-data="keyFigures1Y"
          :fundamentals="fundamentals"
          :calendar-event="calendarEventList"
          :debt-instrument-key-data="debtInstrumentKeyData"
        >
        </symbol-types>
      </div>
    </div>

    <SafraFooter></SafraFooter>
  </div>
</template>
