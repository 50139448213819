import axios from 'axios';
import {noData} from '../../shared/constants';

interface Options {
  identifier: string | number;
  identifierType: string;
}

export async function getDebtInstrument(options: Options) {
  const config = {
    params: options,
  };
  const response = await axios.get('/api/hlapi/debtInstrument', config);
  if (!response?.data) {
    throw new Error(noData);
  }
  return response.data;
}

export async function getInstrumentKeyData(options: Options) {
  const config = {
    params: options,
  };
  const response = await axios.get('/api/hlapi/instrumentKeyData', config);
  if (!response?.data) {
    throw new Error(noData);
  }
  return response.data;
}
