<script lang="ts">
import {defineComponent, computed, ComputedRef} from 'vue';
import {getFormattedNumber, abbreviatedNumber} from '../util/numberHelper';
import {formatDate} from '../util/dateHelper';
import {IType} from '../data-types';

import DayRange from './dayRange.vue';
export default defineComponent({
  name: 'DebtSecurityType',
  components: {
    DayRange,
  },
  props: {
    instrumentData: {
      type: Object,
      required: true,
    },
    priceInfo: {
      type: Object,
      required: true,
    },
    debtInstrumentKeyData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const dateFormat = 'MM.DD.YYYY';
    const minSize: ComputedRef<string> = computed(() =>
      getFormattedNumber(props?.instrumentData?.tradingVolume?.minimum, 2)
    );
    const increment: ComputedRef<string> = computed(() =>
      getFormattedNumber(props?.instrumentData?.tradingVolume?.increment, 2)
    );
    const lastPrice: ComputedRef<string> = computed(() => getFormattedNumber(props?.priceInfo?.latest?.price, 2));
    const previousClosePrice: ComputedRef<string> = computed(() =>
      getFormattedNumber(props?.priceInfo?.previousClose?.price, 2)
    );
    const issueDate: ComputedRef<string> = computed(() =>
      formatDate(props?.instrumentData?.lifeCycle?.issue, dateFormat)
    );
    const coupon: ComputedRef<string> = computed(() =>
      getFormattedNumber(props?.debtInstrumentKeyData?.interestRate?.current?.value, 2)
    );
    const couponType: ComputedRef<string> = computed(
      () => props?.debtInstrumentKeyData?.interestRate?.current?.type?.name
    );
    const couponFrequency: ComputedRef<string> = computed(
      () => props?.debtInstrumentKeyData?.occurrence?.frequency?.name
    );
    const dayCount: ComputedRef<string> = computed(() => props?.debtInstrumentKeyData?.dayCountConvention?.name);
    const accuralFirst: ComputedRef<string> = computed(() =>
      formatDate(props?.debtInstrumentKeyData?.interestRate?.initial?.startAccrual, dateFormat)
    );
    const accurInterest: ComputedRef<string> = computed(() =>
      getFormattedNumber(props?.debtInstrumentKeyData?.interestRate?.accruedInterest?.absolute, 2)
    );

    const callable: ComputedRef<string> = computed(() =>
      props?.instrumentData?.lifeCycle?.maturity?.isCallable ? 'true' : 'false'
    );
    const expiry: ComputedRef<string> = computed(() =>
      formatDate(props?.instrumentData?.lifeCycle?.maturity?.date, dateFormat)
    );
    const types: ComputedRef<IType[]> = computed(() => props?.instrumentData?.type);
    const claimType: ComputedRef<string | undefined> = computed(() => types.value[types.value.length - 1]?.name);
    const bondRating: ComputedRef<string> = computed(() => props?.instrumentData?.debtRanking?.name);
    const issuer: ComputedRef<string> = computed(() => props?.instrumentData?.issuer?.name);
    const issueSize: ComputedRef<string> = computed(() =>
      abbreviatedNumber(props?.instrumentData?.issue?.volume?.amount, 0)
    );

    const pricesData = computed(() => props?.priceInfo);

    return {
      minSize,
      increment,
      lastPrice,
      previousClosePrice,
      issueDate,
      coupon,
      couponType,
      couponFrequency,
      dayCount,
      accuralFirst,
      accurInterest,
      callable,
      expiry,
      claimType,
      bondRating,
      issuer,
      issueSize,
      pricesData,
    };
  },
});
</script>

<template>
  <div class="fds-gray-area">
    <dl>
      <dt>Min size</dt>
      <dd>{{ minSize }}</dd>
      <dt>Increment</dt>
      <dd>{{ increment }}</dd>
      <dt>Last</dt>
      <dd>{{ lastPrice }}</dd>
      <dt>Close</dt>
      <dd>{{ previousClosePrice }}</dd>
      <dt>Coupon</dt>
      <dd>{{ coupon }}</dd>
      <dt>Coupon type</dt>
      <dd>{{ couponType }}</dd>
      <dt>Coupon freq.</dt>
      <dd>{{ couponFrequency }}</dd>
      <dt>Day count</dt>
      <dd>{{ dayCount }}</dd>
      <dt>Issue date</dt>
      <dd>{{ issueDate }}</dd>
      <dt>Accrual first</dt>
      <dd>{{ accuralFirst }}</dd>
      <dt>Callable</dt>
      <dd>{{ callable ? 'Yes' : 'No' }}</dd>
      <dt>Expiry</dt>
      <dd>{{ expiry }}</dd>
      <dt>Claim type</dt>
      <dd>{{ claimType }}</dd>
      <dt>Bond rating</dt>
      <dd>{{ bondRating }}</dd>
      <dt>Issuer</dt>
      <dd>{{ issuer }}</dd>
      <dt>Issue size</dt>
      <dd>{{ issueSize }}</dd>
      <dt>Accr. interest (100K)</dt>
      <dd>{{ accurInterest }}</dd>
    </dl>
    <DayRange :price-info="pricesData"></DayRange>
  </div>
</template>
