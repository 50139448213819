import {defineStore} from 'pinia';
import {validateToken} from '../services/tokenService';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: '' as string,
    idNotation: '',
    loading: false,
    isError: false,
  }),
  actions: {
    async validateJWT() {
      try {
        this.loading = true;
        const response = await validateToken();
        this.idNotation = response.idNotation;
        this.loading = false;
      } catch {
        this.loading = false;
        this.idNotation = '';
        this.isError = true;
      }
    },
  },
});
