export function getSecurityType(securityType: string | undefined) {
  if (securityType === undefined || securityType === '') {
    return 2;
  }
  switch (securityType) {
    case 'stock':
      return 2;
    case 'debt':
      return 3;
    case 'bond':
      return 4;
    case 'options':
      return 5;
    default:
      return 2;
  }
}
