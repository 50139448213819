import {defineStore} from 'pinia';
import {
  getPrices,
  getBidAsk,
  getByInstrument,
  oneWeekKeyFigures,
  oneMonthKeyFigures,
  threeMonthsKeyFigures,
  oneYearKeyFigures,
  calendarEventList,
} from '../services/HLAPI/stockService';
import {getByInstrumentNotation, getByNotation} from '../services/HLAPI/commonService';
import {getDebtInstrument, getInstrumentKeyData} from '../services/HLAPI/debtService';
import {Instrument, IPrices, INotationInfo} from '../data-types';
import {getSecurityType} from '../util/securityTypeHelper';
import {useFAPIStore} from './fapiStore';
import {todayDate, addMonthsToDate} from '../util/dateHelper';
import {calendarCategoryIds, calendarTimeZoneId} from '../shared/constants';

export const useHLAPIStore = defineStore('HLAPI', {
  state: () => ({
    notationByInstrumentInfo: {} as Instrument,
    notationInfo: {} as INotationInfo,
    pricesInfo: {} as IPrices,
    bidAskInfo: {},
    instrumentData: {},
    keyFigures1W: {},
    keyFigures1M: {},
    keyFigures3M: {},
    keyFigures1Y: {},
    debtInstrument: {},
    debtInstrumentKeyData: {},
    loading: false,
    securityType: 0,
    symbol: '',
    calendarEventList: {},
  }),
  actions: {
    async getByInstrumentNotation(idNotation: string | number) {
      if (!idNotation) {
        this.notationByInstrumentInfo = {};
        return;
      }
      try {
        this.loading = true;
        const data = {
          id: idNotation,
        };
        this.notationByInstrumentInfo = await getByInstrumentNotation(data);
        this.securityType = getSecurityType(this.notationByInstrumentInfo?.assetClass?.code);
        if (this.securityType === 2) {
          await this.fetchStockData(idNotation);
        } else if (this.securityType === 3) {
          await this.fetchDebt(idNotation);
        }
        this.loading = false;
      } catch {
        this.loading = false;
        this.notationByInstrumentInfo = {};
      }
    },

    async getByNotation(idNotation: string | number) {
      try {
        this.loading = true;
        const data = {
          identifier: idNotation,
          identifierType: 'idNotation',
        };
        const response = await getByNotation(data);
        this.notationInfo = response;
        this.symbol = this.notationInfo?.symbol ?? '';
        this.loading = false;
      } catch {
        this.loading = false;
        this.notationInfo = {} as INotationInfo;
      }
    },

    async fetchPrices(idNotation: string | number) {
      try {
        this.loading = true;
        const data = {
          id: idNotation,
        };
        const response = await getPrices(data);
        this.pricesInfo = response;
        this.loading = false;
      } catch {
        this.loading = false;
        this.pricesInfo = {} as IPrices;
      }
    },

    async fetchBidAsk(idNotation: string | number) {
      try {
        this.loading = true;
        const data = {
          id: idNotation,
        };
        const response = await getBidAsk(data);
        this.bidAskInfo = response;
        this.loading = false;
      } catch {
        this.loading = false;
        this.bidAskInfo = {};
      }
    },

    async fetchInstrument(idNotation: string | number) {
      try {
        this.loading = true;
        const data = {
          data: {
            identifier: {
              value: idNotation,
              type: 'idNotation',
            },
            currency: {
              isoCode: 'USD',
            },
          },
        };
        const response = await getByInstrument(data);
        this.instrumentData = response;
        this.loading = false;
      } catch {
        this.loading = false;
        this.instrumentData = {};
      }
    },
    async fetchOneWeekKeyFigures(idNotation: string | number) {
      try {
        this.loading = true;
        const data = {
          id: idNotation,
        };
        const response = await oneWeekKeyFigures(data);
        this.keyFigures1W = response;
        this.loading = false;
      } catch {
        this.loading = false;
        this.keyFigures1W = {};
      }
    },

    async fetchOneMonthKeyFigures(idNotation: string | number) {
      try {
        this.loading = true;
        const data = {
          id: idNotation,
        };
        const response = await oneMonthKeyFigures(data);
        this.keyFigures1M = response;
        this.loading = false;
      } catch {
        this.loading = false;
        this.keyFigures1M = {};
      }
    },

    async fetchThreeMonthsKeyFigures(idNotation: string | number) {
      try {
        this.loading = true;
        const data = {
          id: idNotation,
        };
        const response = await threeMonthsKeyFigures(data);
        this.keyFigures3M = response;
        this.loading = false;
      } catch {
        this.loading = false;
        this.keyFigures3M = {};
      }
    },
    async fetchOneYearKeyFigures(idNotation: string | number) {
      try {
        this.loading = true;
        const data = {
          id: idNotation,
        };
        const response = await oneYearKeyFigures(data);
        this.keyFigures1Y = response;
        this.loading = false;
      } catch {
        this.loading = false;
        this.keyFigures1Y = {};
      }
    },

    async fetchDebtInstrument(idNotation: string | number) {
      try {
        this.loading = true;
        const data = {
          identifier: idNotation,
          identifierType: 'idNotation',
        };
        const response = await getDebtInstrument(data);
        this.instrumentData = response;
        this.loading = false;
      } catch {
        this.loading = false;
        this.instrumentData = {};
      }
    },
    async fetchInstrumentKeyData(idNotation: string | number) {
      try {
        this.loading = true;
        const data = {
          identifier: idNotation,
          identifierType: 'idNotation',
        };
        const response = await getInstrumentKeyData(data);
        this.debtInstrumentKeyData = response;
        this.loading = false;
      } catch {
        this.loading = false;
        this.debtInstrumentKeyData = {};
      }
    },

    async fetchDebt(idNotation: string | number) {
      await Promise.allSettled([
        this.getByNotation(idNotation),
        this.fetchPrices(idNotation),
        this.fetchDebtInstrument(idNotation),
        this.fetchInstrumentKeyData(idNotation),
      ]);
      await this.fetchFAPI(idNotation);
    },

    async fetchStockData(idNotation: string | number) {
      await Promise.allSettled([
        this.getByNotation(idNotation),
        this.fetchPrices(idNotation),
        this.fetchBidAsk(idNotation),
        this.fetchInstrument(idNotation),
        this.fetchOneWeekKeyFigures(idNotation),
        this.fetchOneMonthKeyFigures(idNotation),
        this.fetchThreeMonthsKeyFigures(idNotation),
        this.fetchOneYearKeyFigures(idNotation),
      ]);
      await Promise.allSettled([this.fetchCalendarEventList(), this.fetchFAPI(idNotation)]);
    },

    async fetchFAPI(idNotation: string | number) {
      const fapiStore = useFAPIStore();
      if (this.notationInfo && this.symbol) {
        const {symbol} = this;
        const fapiOptions = {
          identifier: `US:${symbol}`,
        };
        await Promise.allSettled([
          fapiStore.fetchFundamentals(fapiOptions),
          fapiStore.fetchSnapQuotes({
            identifiers: `xx:${idNotation}`,
          }),
        ]);
      }
    },
    async fetchCalendarEventList() {
      try {
        const idInstrument = Number(this.notationInfo.instrument.id);
        if (!idInstrument) {
          return;
        }
        this.loading = true;
        this.calendarEventList = {};
        const start = todayDate('YYYY-MM-DD');
        const end = addMonthsToDate(start, 3, 'YYYY-MM-DD');
        const data = {
          data: {
            filter: {
              date: {
                range: {
                  start,
                  end,
                },
                timezone: {
                  id: calendarTimeZoneId,
                },
              },
              category: {
                ids: calendarCategoryIds,
              },
              instrument: {
                ids: [idInstrument],
              },
            },
          },
        };
        const response = await calendarEventList(data);
        this.loading = false;
        this.calendarEventList = response[0];
      } catch {
        this.loading = false;
        this.calendarEventList = {};
      }
    },
  },
});
