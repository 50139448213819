import axios from 'axios';
import {Options, InstrumentOptions, CalendarEventOptions} from '../../data-types';
import {noData} from '../../shared/constants';

export async function getPrices(options: Options) {
  const config = {
    params: options,
  };
  const response = await axios.get('/api/hlapi/getPrices', config);
  if (!response?.data) {
    throw new Error(noData);
  }
  return response.data;
}

export async function getBidAsk(options: Options) {
  const config = {
    params: options,
  };
  const response = await axios.get('/api/hlapi/getBidAsk', config);
  if (!response?.data) {
    throw new Error(noData);
  }
  return response.data;
}

export async function getByInstrument(bodyParams: InstrumentOptions) {
  const response = await axios.post('/api/hlapi/getByInstrument', bodyParams);
  if (!response?.data) {
    throw new Error(noData);
  }
  return response.data;
}

export async function oneWeekKeyFigures(options: Options) {
  const config = {
    params: options,
  };
  const response = await axios.get('/api/hlapi/oneWeekKeyFigures', config);
  if (!response?.data) {
    throw new Error(noData);
  }
  return response.data;
}

export async function oneMonthKeyFigures(options: Options) {
  const config = {
    params: options,
  };
  const response = await axios.get('/api/hlapi/oneMonthKeyFigures', config);
  if (!response?.data) {
    throw new Error(noData);
  }
  return response.data;
}
export async function threeMonthsKeyFigures(options: Options) {
  const config = {
    params: options,
  };
  const response = await axios.get('/api/hlapi/threeMonthsKeyFigures', config);
  if (!response?.data) {
    throw new Error(noData);
  }
  return response.data;
}
export async function oneYearKeyFigures(options: Options) {
  const config = {
    params: options,
  };
  const response = await axios.get('/api/hlapi/oneYearKeyFigures', config);
  if (!response?.data) {
    throw new Error(noData);
  }
  return response.data;
}

export async function calendarEventList(options: CalendarEventOptions) {
  const response = await axios.post('/api/hlapi/calendarEventList', options);
  if (!response?.data) {
    throw new Error(noData);
  }
  return response.data;
}
