import {defaultTimeZone, nullValue} from '../shared/constants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone.js';

dayjs.extend(utc);
dayjs.extend(timezone);

export function formatTime(datetime: string | Date) {
  if (!datetime) {
    return nullValue;
  }
  const dateObj = typeof datetime === 'string' ? new Date(datetime) : datetime;

  const formatter = new Intl.DateTimeFormat('en-US', {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
    timeZone: defaultTimeZone,
    timeZoneName: 'shortGeneric',
  });
  const date = new Date(dateObj);
  return formatter.format(date);
}

// milliseconds string or number or date string like 15232323,'15232323' or '2023/12/12'
export function formatDate(date: string | number, format: string) {
  if (!date) {
    return nullValue;
  }
  return dayjs(date).format(format);
}

// milliseconds string or number or date string like 15232323,'15232323' or '2023/12/12
export function formatDateTimeWithTimeZone(date: string | number, format: string) {
  if (!date) {
    return nullValue;
  }

  return dayjs.tz(date, 'America/New_York').format(format);
}

export const todayDate = function (format: string) {
  return dayjs().format(format);
};
export const addMonthsToDate = function (date: string, numberOfMonths: number, format: string) {
  if (!date) {
    return nullValue;
  }
  return dayjs(date).add(numberOfMonths, 'month').format(format);
};
