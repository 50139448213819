<script lang="ts">
import {defineComponent, computed, ComputedRef} from 'vue';
import {CssHelper} from '../util/cssHelper';
import {getFormattedNumber} from '../util/numberHelper';
import {formatTime} from '../util/dateHelper';
export default defineComponent({
  name: 'PriceWidgetHeader',
  props: {
    instrumentData: {
      type: Object,
    },
    priceInfo: {
      type: Object,
    },
    notationInfo: {
      type: Object,
    },
  },
  setup(props) {
    const price: ComputedRef<string> = computed(() => props?.priceInfo?.latest?.performance?.intraday?.relative ?? '');
    const time: ComputedRef<string> = computed(() => formatTime(props?.priceInfo?.latest?.time));

    const changePercent: ComputedRef<string> = computed(() => getFormattedNumber(price.value, 2));
    const changePercentClass: ComputedRef<string> = computed(() => CssHelper.getClassForChange(changePercent.value));
    const latestPrice: ComputedRef<string> = computed(() => getFormattedNumber(props?.priceInfo?.latest?.price, 2));
    const valueUnit: ComputedRef<string> = computed(() => props?.notationInfo?.valueUnit?.code);
    const exchange: ComputedRef<string> = computed(() => props?.notationInfo?.market?.name);
    const symbol: ComputedRef<string> = computed(() => props?.instrumentData?.name);
    const isin: ComputedRef<string> = computed(() => props?.instrumentData?.isin);

    return {changePercent, changePercentClass, time, latestPrice, valueUnit, exchange, symbol, isin};
  },
});
</script>

<template>
  <div class="fds-quote">
    <div class="fds-quote-top">
      <h3>{{ symbol }}</h3>
      <span><span class="fds-bold">ISIN:</span>{{ isin }}</span>
      <span>{{ exchange }}</span>
    </div>

    <div class="fds-quote-bottom">
      <div class="fds-content-left">
        <span class="fds-quote-label">Last price</span>
        <span>{{ valueUnit }}</span>
      </div>
      <div class="fds-content-right">
        <span class="fds-price">
          {{ latestPrice }}
        </span>
        <span :class="changePercentClass">{{ changePercent }}</span>
      </div>
      <span class="fds-timestamp">{{ time }}</span>
    </div>
  </div>
</template>
