<script lang="ts">
import {defineComponent, computed, ComputedRef} from 'vue';
import {getFormattedNumber} from '../util/numberHelper';
export default defineComponent({
  name: 'DayRange',
  props: {
    priceInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const currentPrice: ComputedRef<string> = computed(() => getFormattedNumber(props?.priceInfo?.latest?.price, 2));
    const lowPrice: ComputedRef<string> = computed(() => getFormattedNumber(props?.priceInfo?.low?.price, 2));
    const highPrice: ComputedRef<string> = computed(() => getFormattedNumber(props?.priceInfo?.high?.price, 2));
    const ariaLabelTemplate = `Low ${lowPrice.value}, Current ${currentPrice.value}, High ${highPrice.value}`;

    return {
      currentPrice,
      lowPrice,
      highPrice,
      ariaLabelTemplate,
    };
  },
});
</script>

<template>
  <div class="fds-day-range-wrapper">
    <span>Day Range</span>
    <div :aria-label="ariaLabelTemplate" class="fds-day-range-slider">
      <div class="fds-range-upper">
        <span aria-label="Low">{{ lowPrice }}</span>
        <span aria-label="High">{{ highPrice }}</span>
      </div>
      <div class="fds-range-lower">
        <span class="fds-range-dot" style="margin-left: 20%" aria-label="Current"
          ><span class="sr-only">{{ currentPrice }}</span></span
        >
      </div>
    </div>
  </div>
</template>
