import axios from 'axios';
import {noData} from '../shared/constants';

export async function validateToken() {
  const response = await axios.post('/api/verifyToken');
  if (!response?.data) {
    throw new Error(noData);
  }
  return response?.data;
}
