<script lang="ts">
import {defineComponent, computed, ComputedRef} from 'vue';
import {CssHelper} from '../util/cssHelper';
import {getFormattedNumber, abbreviatedNumber} from '../util/numberHelper';
import {formatDate} from '../util/dateHelper';
import DayRange from './dayRange.vue';
export default defineComponent({
  name: 'StockSecurityType',
  components: {
    DayRange,
  },
  props: {
    priceInfo: {
      type: Object,
      required: true,
    },
    bidAskInfo: {
      type: Object,
      required: true,
    },
    instrumentData: {
      type: Object,
      required: true,
    },
    keyFiguresOneWeekData: {
      type: Object,
      required: true,
    },
    keyFiguresOneMonthData: {
      type: Object,
      required: true,
    },
    keyFiguresThreeMonthData: {
      type: Object,
      required: true,
    },
    keyFiguresOneYearData: {
      type: Object,
      required: true,
    },
    fundamentals: {
      type: Object,
      required: true,
    },
    calendarEvent: {
      type: Object,
    },
  },
  setup(props) {
    const performance: ComputedRef<string> = computed(() => props?.keyFiguresOneWeekData?.performance.relative);
    const oneWPerformanceClass: ComputedRef<string> = computed(() =>
      CssHelper.getClassForChange(oneWPerformance.value)
    );
    const oneWPerformance: ComputedRef<string> = computed(() =>
      performance.value ? `${getFormattedNumber(performance.value, 2)}%` : ''
    );

    const monthPerformance: ComputedRef<string> = computed(() => props?.keyFiguresOneMonthData?.performance.relative);
    const oneMonthPerformance: ComputedRef<string> = computed(() =>
      monthPerformance.value ? `${getFormattedNumber(monthPerformance.value, 2)}%` : ''
    );
    const oneMonthPerformanceClass: ComputedRef<string> = computed(() =>
      CssHelper.getClassForChange(oneMonthPerformance.value)
    );

    const yearPerformance: ComputedRef<string> = computed(() => props?.keyFiguresOneYearData?.performance?.relative);
    const oneYearPerformance: ComputedRef<string> = computed(() =>
      yearPerformance.value ? `${getFormattedNumber(yearPerformance.value, 2)}%` : ''
    );
    const oneYearPerformanceClass: ComputedRef<string> = computed(() =>
      CssHelper.getClassForChange(oneYearPerformance.value)
    );

    const previousClose: ComputedRef<string> = computed(() =>
      getFormattedNumber(props?.priceInfo.previousClose?.price, 2)
    );
    const open: ComputedRef<string> = computed(() => getFormattedNumber(props?.priceInfo?.first?.price, 2));
    const bid: ComputedRef<string> = computed(() => getFormattedNumber(props?.bidAskInfo.bid?.price, 2));
    const ask: ComputedRef<string> = computed(() => getFormattedNumber(props?.bidAskInfo.ask?.price, 2));
    const bidSize: ComputedRef<string> = computed(() => abbreviatedNumber(props?.bidAskInfo.bid?.tradingVolume, 2));
    const askSize: ComputedRef<string> = computed(() => abbreviatedNumber(props?.bidAskInfo.ask?.tradingVolume, 2));
    const marketCap: ComputedRef<string> = computed(() =>
      abbreviatedNumber(
        props?.instrumentData?.companyKeyFigures?.currencyDependentKeyFigures?.marketCapitalizationSharesOutstanding
          ?.value,
        2
      )
    );
    const volume: ComputedRef<string> = computed(() =>
      abbreviatedNumber(props?.priceInfo?.accumulated?.tradingVolume, 2)
    );
    const avgVolume: ComputedRef<string> = computed(() =>
      abbreviatedNumber(props?.keyFiguresOneMonthData?.tradingVolume?.average, 2)
    );
    const peRatio: ComputedRef<string> = computed(() =>
      abbreviatedNumber(props?.instrumentData?.shareInstrumentKeyFigures?.ratios?.priceEarnings.value, 2)
    );
    const eps: ComputedRef<string> = computed(() =>
      getFormattedNumber(
        props?.instrumentData?.companyKeyFigures?.currencyDependentKeyFigures?.perShare?.earnings.value,
        2
      )
    );
    const dividend: ComputedRef<string> = computed(() =>
      getFormattedNumber(
        props?.instrumentData?.shareInstrumentKeyFigures?.currencyDependentKeyFigures?.perShare?.dividend.value,
        2
      )
    );
    const currentPrice: ComputedRef<string> = computed(() => getFormattedNumber(props?.priceInfo?.latest?.price, 2));
    const lowPrice: ComputedRef<string> = computed(() => getFormattedNumber(props?.priceInfo?.low?.price, 2));
    const highPrice: ComputedRef<string> = computed(() => getFormattedNumber(props?.priceInfo?.high?.price, 2));

    const pricesData = computed(() => props.priceInfo);
    const beta: ComputedRef<string> = computed(() => getFormattedNumber(props?.fundamentals?.threeYearBeta, 2));
    const exDividendDate: ComputedRef<string> = computed(() =>
      formatDate(props?.fundamentals?.dividend?.exDividendDate, 'MM.DD.YYYY')
    );
    const earningsDate: ComputedRef<string> = computed(() =>
      formatDate(props?.calendarEvent?.schedule?.date?.start, 'MM.DD.YYYY')
    );

    return {
      previousClose,
      open,
      bid,
      ask,
      bidSize,
      askSize,
      marketCap,
      volume,
      avgVolume,
      peRatio,
      eps,
      dividend,
      oneWPerformance,
      oneWPerformanceClass,
      oneMonthPerformance,
      oneYearPerformance,
      oneMonthPerformanceClass,
      oneYearPerformanceClass,
      currentPrice,
      lowPrice,
      highPrice,
      pricesData,
      beta,
      exDividendDate,
      earningsDate,
    };
  },
});
</script>

<template>
  <div class="fds-gray-area">
    <dl>
      <dt>Previous close</dt>
      <dd>
        {{ previousClose }}
      </dd>

      <dt>Open</dt>
      <dd>{{ open }}</dd>

      <dt>Bid</dt>
      <dd>{{ bid }}</dd>

      <dt>Ask</dt>
      <dd>{{ ask }}</dd>

      <dt>Bid size</dt>
      <dd>
        {{ bidSize }}
      </dd>

      <dt>Ask size</dt>
      <dd>
        {{ askSize }}
      </dd>

      <dt>Market cap</dt>
      <dd>
        {{ marketCap }}
      </dd>

      <dt>Volume</dt>
      <dd>
        {{ volume }}
      </dd>

      <dt>Average vol (3M)</dt>
      <dd>{{ avgVolume }}</dd>

      <dt>P/E ratio</dt>
      <dd>
        {{ peRatio }}
      </dd>

      <dt>EPS</dt>
      <dd>
        {{ eps }}
      </dd>

      <dt>Beta</dt>
      <dd>{{ beta }}</dd>

      <dt>Dividend</dt>
      <dd>{{ dividend }}</dd>

      <dt>Ex-dividend date</dt>
      <dd>{{ exDividendDate }}</dd>

      <dt>Earnings date</dt>
      <dd>{{ earningsDate }}</dd>

      <dt>1W Performance</dt>
      <dd>
        <span :class="oneWPerformanceClass">
          {{ oneWPerformance }}
        </span>
      </dd>

      <dt>1M Performance</dt>
      <dd>
        <span :class="oneMonthPerformanceClass">{{ oneMonthPerformance }}</span>
      </dd>

      <dt>1Y Performance</dt>
      <dd>
        <span :class="oneYearPerformanceClass">{{ oneYearPerformance }}</span>
      </dd>
    </dl>
    <DayRange :price-info="pricesData"></DayRange>
  </div>
</template>
