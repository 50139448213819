import {createApp} from 'vue';
import App from './App.vue';
import {router} from './router';
import {getPrefetchData} from '@fds/html-prefetch-stream';
import Highcharts from 'highcharts';
import stockModule from 'highcharts/modules/stock';
import HighchartsVue from 'highcharts-vue';
import {useAuthStore} from './store/authStore';
import {getFormattedNumber, abbreviatedNumber, numberWithCommas} from './util/numberHelper';
import {formatTime} from './util/dateHelper';
import {createPinia} from 'pinia';
import axios from 'axios';

stockModule(Highcharts);

const app = createApp(App);
app.use(getPrefetchData);
app.use(createPinia());
app.use(router);

app.config.globalProperties.$filters = {
  abbreviatedNumber,
  getFormattedNumber,
  numberWithCommas,
  formatTime,
};

interface PreFetchData {
  ssoToken: string;
}

getPrefetchData<PreFetchData>()
  .then((prefetchData: PreFetchData) => {
    if (prefetchData.ssoToken) {
      const authStore = useAuthStore();
      axios.interceptors.request.use((config) => {
        config.headers.authToken = `${prefetchData.ssoToken}`;
        return config;
      });
      authStore.$patch({
        token: prefetchData.ssoToken,
      });
    }
  })
  .catch(() => {
    console.error('An error occured while prefetching data');
  });
// Use the HighchartsVue plugin, register <highcharts> component
app.use(HighchartsVue);
app.mount('#app');
